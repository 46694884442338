import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { Realization, ListResponse, RealizationStatuses } from '@/types';
import { useRoles } from '@/hooks';
import { Pagination } from '@/components';
import { ExternalLinkOutlined, FileFlat, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { REALIZATION_STATUSES } from '..';
import { DeleteRealization, GenerateRealizationDocument } from '../components';

export interface RealizationListProps {
  list?: ListResponse<Realization>;
  page: number;
  disabled: boolean;
  onChangePage(page: number): void;
}

const RealizationsTable: React.FC<RealizationListProps> = ({ list, page, disabled, onChangePage }) => {
  const { hasRoles } = useRoles();

  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [generateDocumentAnchorEl, setGenerateDocumentAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentRealization, setCurrentRealization] = useState<Realization | null>(null);

  function openDeleteModal(realization: Realization) {
    setCurrentRealization(realization);
    setOpenedDeleteModal(true);
  }

  function openGenerateDocumentModal(event: React.MouseEvent<HTMLButtonElement>, realization: Realization) {
    setCurrentRealization(realization);
    setGenerateDocumentAnchorEl(event.currentTarget);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer
          component={Paper}
          sx={{
            ...(disabled && { opacity: 0.5, pointerEvents: 'none', userSelect: 'none' }),
            maxHeight: `calc(100vh - ${theme.spacing(34.5)})`,
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell>Время</TableCell>
                <TableCell>Наименование</TableCell>
                <TableCell />
                <TableCell>Залоги</TableCell>
                <TableCell>Автор</TableCell>
                <TableCell>Изменён</TableCell>
                <TableCell>Проведён</TableCell>
                <TableCell>Статус</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((realization) => {
                const { name, created_at, employee, updated_by, carried_by, status, id } = realization,
                  realizationStatus = REALIZATION_STATUSES.find(({ value }) => value === status);

                return (
                  <TableRow key={id}>
                    <TableCell>{moment(created_at).format('DD.MM.YYYY')}</TableCell>
                    <TableCell>{moment(created_at).format('HH:mm')}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title={realizationStatus?.label}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: 32,
                              height: 32,
                            }}
                          >
                            {realizationStatus?.icon}
                          </Box>
                        </Tooltip>

                        {hasRoles(['ADMIN']) && (
                          <Tooltip title="Удалить">
                            <IconButton aria-label="Удалить" onClick={() => openDeleteModal(realization)}>
                              <TrashFlat sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        )}

                        {![RealizationStatuses.CANCELLED].includes(status!) && (
                          <Tooltip title="Печать">
                            <IconButton
                              aria-label="Печать"
                              onClick={(event) => openGenerateDocumentModal(event, realization)}
                            >
                              <FileFlat sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Stack>
                    </TableCell>

                    <TableCell>
                      <Tooltip title="Залоги">
                        <IconButton
                          component={RouterLink}
                          to={`/operations/realizations/${id}/pledges`}
                          aria-label="Залоги"
                        >
                          <ExternalLinkOutlined sx={{ fontSize: 16 }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      {employee ? `${employee?.last_name} ${employee?.name} ${employee?.father_name}` : '—'}
                    </TableCell>

                    <TableCell>
                      {updated_by ? `${updated_by?.last_name} ${updated_by?.name} ${updated_by?.father_name}` : '—'}
                    </TableCell>

                    <TableCell>
                      {carried_by ? `${carried_by?.last_name} ${carried_by?.name} ${carried_by?.father_name}` : '—'}
                    </TableCell>

                    <TableCell>{realizationStatus?.label}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          disabled={disabled}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <DeleteRealization
        realization={currentRealization!}
        open={deleteModalOpened}
        onClose={() => setOpenedDeleteModal(false)}
      />
      <GenerateRealizationDocument
        realization={currentRealization!}
        open={generateDocumentAnchorEl !== null}
        anchorEl={generateDocumentAnchorEl}
        onClose={() => setGenerateDocumentAnchorEl(null)}
      />
    </>
  );
};

export default RealizationsTable;
