import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { FormikHelpers } from 'formik';
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { green, pink } from '@mui/material/colors';

import { Pledge, ListResponse, PledgeStatuses } from '@/types';
import { useUpdatePledgeMutation } from '@/services';
import { useRoles } from '@/hooks';
import { Pagination } from '@/components';
import { EditFlat, ExternalLinkOutlined, FileFlat, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { PLEDGE_STATUSES } from '..';
import { DeletePledge, GeneratePledgeDocument, UpdatePledge } from '../components';

export interface PledgeListProps {
  list?: ListResponse<Pledge>;
  page: number;
  disabled: boolean;
  refetch(): void;
  onChangePage(page: number): void;
}

const PledgesTable: React.FC<PledgeListProps> = ({ list, page, disabled, refetch, onChangePage }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { hasRoles } = useRoles();

  const [updatePledge, updatePledgeResponse] = useUpdatePledgeMutation();

  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [generateDocumentAnchorEl, setGenerateDocumentAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentPledge, setCurrentPledge] = useState<Pledge | null>(null);

  function openUpdateModal(pledge: Pledge) {
    setCurrentPledge(pledge);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(pledge: Pledge) {
    setCurrentPledge(pledge);
    setOpenedDeleteModal(true);
  }

  function openGenerateDocumentModal(event: React.MouseEvent<HTMLButtonElement>, pledge: Pledge) {
    setCurrentPledge(pledge);
    setGenerateDocumentAnchorEl(event.currentTarget);
  }

  async function handleUpdatePledge(pledge: Pledge, { resetForm }: FormikHelpers<Pledge>) {
    try {
      await updatePledge(pledge).unwrap();

      setOpenedUpdateModal(false);
      resetForm();
    } catch (error) {
      enqueueSnackbar(error.data, { variant: 'error' });
    }
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer
          component={Paper}
          sx={{
            ...(disabled && { opacity: 0.5, pointerEvents: 'none', userSelect: 'none' }),
            maxHeight: `calc(100vh - ${theme.spacing(34.5)})`,
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell>Время</TableCell>
                <TableCell>Номер</TableCell>
                <TableCell>Клиент</TableCell>
                <TableCell />
                <TableCell>На руки</TableCell>
                <TableCell>% ставка</TableCell>
                <TableCell>К возврату</TableCell>
                <TableCell>Дата возврата</TableCell>
                <TableCell>Статус</TableCell>
                <TableCell>Номенклатурная группа</TableCell>
                <TableCell>Точка (Ломбард)</TableCell>
                <TableCell>Пролонгирован до</TableCell>
                <TableCell>Пролонгации</TableCell>
                <TableCell>Выкупы</TableCell>
                <TableCell>Автор</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((pledge) => {
                const {
                    created_at,
                    carried_by,
                    name,
                    nomenclature_group,
                    point,
                    prolongated_until_date,
                    customer,
                    first_pledge_id,
                    return_sum_to_hand,
                    return_sum,
                    rate_for_day,
                    return_date,
                    status,
                    is_composite,
                    re_mortgage,
                    id,
                  } = pledge,
                  pledgeStatus = PLEDGE_STATUSES.find(({ value }) => value === status)!;

                return (
                  <TableRow
                    key={id}
                    sx={{
                      backgroundColor: re_mortgage
                        ? green[50]
                        : !is_composite
                        ? null
                        : first_pledge_id
                        ? pink[50]
                        : pink[100],
                    }}
                  >
                    <TableCell>{moment(created_at).format('DD.MM.YYYY')}</TableCell>
                    <TableCell>{moment(created_at).format('HH:mm')}</TableCell>
                    <TableCell>{name ?? '—'}</TableCell>
                    <TableCell>
                      {customer?.last_name ?? ''} {customer?.name ?? ''} {customer?.father_name ?? ''}
                    </TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title={pledgeStatus.label}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: 32,
                              height: 32,
                            }}
                          >
                            {pledgeStatus.icon}
                          </Box>
                        </Tooltip>

                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(pledge)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        {![PledgeStatuses.CANCELLED].includes(status!) && (
                          <Tooltip title="Печать">
                            <IconButton
                              aria-label="Печать"
                              onClick={(event) => openGenerateDocumentModal(event, pledge)}
                            >
                              <FileFlat sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        )}

                        {hasRoles(['ADMIN']) && (
                          <Tooltip title="Удалить">
                            <IconButton aria-label="Удалить" onClick={() => openDeleteModal(pledge)}>
                              <TrashFlat sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>{return_sum_to_hand}</TableCell>
                    <TableCell>{rate_for_day}</TableCell>
                    <TableCell>{return_sum}</TableCell>
                    <TableCell>{moment(return_date).format('DD.MM.YYYY')}</TableCell>
                    <TableCell>{pledgeStatus.label}</TableCell>
                    <TableCell>{nomenclature_group?.name ?? '—'}</TableCell>

                    <TableCell>{point?.name ?? '—'}</TableCell>
                    <TableCell>
                      {prolongated_until_date ? moment(prolongated_until_date).format('DD.MM.YYYY') : '—'}
                    </TableCell>

                    <TableCell>
                      <Tooltip title="Пролонгации">
                        <IconButton
                          component={RouterLink}
                          to={`/operations/pledges/${id}/prolongations`}
                          aria-label="Пролонгации"
                        >
                          <ExternalLinkOutlined sx={{ fontSize: 16 }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      <Tooltip title="Выкупы">
                        <IconButton component={RouterLink} to={`/operations/pledges/${id}/buyouts`} aria-label="Выкупы">
                          <ExternalLinkOutlined sx={{ fontSize: 16 }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      {carried_by?.last_name ?? ''} {carried_by?.name ?? ''} {carried_by?.father_name ?? ''}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          disabled={disabled}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdatePledge
        pledge={currentPledge!}
        customer={null}
        open={updateModalOpened}
        disabled={updatePledgeResponse.isLoading}
        refetch={refetch}
        onSubmit={handleUpdatePledge}
        onClose={() => setOpenedUpdateModal(false)}
      />
      <DeletePledge pledge={currentPledge!} open={deleteModalOpened} onClose={() => setOpenedDeleteModal(false)} />
      <GeneratePledgeDocument
        pledge={currentPledge!}
        open={generateDocumentAnchorEl !== null}
        anchorEl={generateDocumentAnchorEl}
        onClose={() => setGenerateDocumentAnchorEl(null)}
      />
    </>
  );
};

export default PledgesTable;
