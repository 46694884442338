import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import moment from 'moment';
import { Box, Button, Grid, MenuItem, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { Customer, PledgeStatuses } from '@/types';
import { useGetPledgesQuery } from '@/services';
import { useAuth, useRoles, useShifts } from '@/hooks';
import { AppBar, DebouncedTextField, Empty, Error, Loader } from '@/components';
import { BlankFlat, CheckFlat, ClockThreeOutlined, CoinsFlat, TimesFlat } from '@/assets/icons';
import { NomenclatureGroups } from '@/utils/enums';
import { OrderDirections, ORDER_DIRECTIONS } from '@/utils/order-directions';

import { PledgesTable, CreatePledge } from './components';
import { FurCoat, Jewelry, Other, Technique } from './components/CollateralItemByNomenclatureGroup';
import { exclude } from '@/utils/helpers';
import { useCreatePledge } from './hooks';

export const NOMENCLATURE_GROUP_PROPERTIES: {
  name: NomenclatureGroups;
  component: React.FC<{ customer: Customer }>;
}[] = [
  { name: NomenclatureGroups.FUR_COATS, component: FurCoat },
  { name: NomenclatureGroups.JEVELRY, component: Jewelry },
  { name: NomenclatureGroups.TECHNIQUE, component: Technique },
  { name: NomenclatureGroups.OTHER, component: Other },
];

export const PLEDGE_STATUSES = [
  { label: 'Проведён', value: PledgeStatuses.CARRIED_OUT, icon: <CheckFlat sx={{ fontSize: 16 }} color="success" /> },
  { label: 'Отменён', value: PledgeStatuses.CANCELLED, icon: <TimesFlat sx={{ fontSize: 16 }} color="error" /> },
  { label: 'Выкуплен', value: PledgeStatuses.FINISHED, icon: <CoinsFlat sx={{ fontSize: 16 }} color="primary" /> },
  { label: 'Реализован', value: PledgeStatuses.REALIZED, icon: <BlankFlat sx={{ fontSize: 16 }} color="info" /> },
  {
    label: 'Пролонгирован',
    value: PledgeStatuses.PROLONGED,
    icon: <ClockThreeOutlined sx={{ fontSize: 16 }} color="secondary" />,
  },
  {
    label: 'Просрочен',
    value: PledgeStatuses.OVERDUE,
    icon: null,
  },
];

const PledgesPage: React.FC = () => {
  const { user } = useAuth();
  const { shift, isShiftOpened, isShiftLoading, openShiftLoading, handleLoadShift } = useShifts();
  const { isCachier } = useRoles();
  const { customerId, realizationId } = useParams<{ customerId: string; realizationId: string }>();

  const [{ search, active, order_direction, status, page, date_from, date_to }, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    active: withDefault(StringParam, ''),
    status: withDefault(StringParam, ''),
    order_direction: withDefault(StringParam, OrderDirections.DESC),
    date_from: withDefault(DateParam, [customerId, realizationId].some(Boolean) ? null : moment().toDate()),
    date_to: withDefault(DateParam, moment().toDate()),
  });

  const [createModalOpened, setOpenedCreateModal] = useState(false);

  const {
    data: pledges,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetPledgesQuery({
    params: {
      search: search ? search : null,
      order_direction,
      status,
      page,
      order_by: 'id',
      customer_id: customerId,
      realization_id: realizationId,
      active: active === '' ? null : active === '1' ? '1' : '0',
      date_from: date_from ? moment(date_from).format('YYYY-MM-DD') : null,
      date_to: moment(date_to).format('YYYY-MM-DD'),
    },
  });

  const { createPledgeResponse, handleCreatePledge } = useCreatePledge((_, { resetForm }) => {
    setOpenedCreateModal(false);
    resetForm();
  });

  return (
    <>
      <AppBar title="Залоги" />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="flex-start" spacing={3}>
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <DebouncedTextField
                  type="search"
                  name="search"
                  label="Поиск"
                  placeholder="Введите значение"
                  value={search}
                  onChange={(event) => setQuery({ search: event.target.value, page: 1 })}
                  variant="filled"
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <TextField
                  select
                  value={order_direction}
                  label="Сортировать по"
                  disabled={isLoading}
                  onChange={(event) => setQuery({ order_direction: event.target.value })}
                  variant="filled"
                  fullWidth
                  size="small"
                >
                  {ORDER_DIRECTIONS.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <TextField
                  select
                  value={active}
                  label="Активные"
                  disabled={isLoading}
                  onChange={(event) => setQuery({ active: event.target.value })}
                  variant="filled"
                  fullWidth
                  size="small"
                >
                  <MenuItem value="">Все</MenuItem>

                  {[
                    { label: 'Активные', value: 1 },
                    { label: 'Неактивные', value: 0 },
                  ].map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <TextField
                  select
                  value={status}
                  label="Статус"
                  disabled={isLoading}
                  onChange={(event) => setQuery({ status: event.target.value })}
                  variant="filled"
                  fullWidth
                  size="small"
                >
                  <MenuItem value="">Все</MenuItem>

                  {exclude(PLEDGE_STATUSES, 'value', PledgeStatuses.OVERDUE).map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <DatePicker
                  mask="__.__.____"
                  label="Дата от"
                  value={date_from}
                  minDate={moment(date_to).subtract(6, 'months')}
                  maxDate={moment(date_to)}
                  onChange={(value) => setQuery({ date_from: value ? moment(value).toDate() : null })}
                  disabled={isLoading || isFetching}
                  renderInput={(params) => (
                    <TextField {...params} name="date_from" variant="filled" size="small" fullWidth />
                  )}
                />
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <DatePicker
                  mask="__.__.____"
                  label="Дата до"
                  value={date_to}
                  maxDate={moment()}
                  onChange={(value) => setQuery({ date_to: value ? moment(value).toDate() : undefined })}
                  disabled={isLoading || isFetching}
                  renderInput={(params) => (
                    <TextField {...params} name="date_to" variant="filled" size="small" fullWidth />
                  )}
                />
              </Grid>
            </Grid>

            {isCachier && shift && (
              <>
                {!isShiftOpened ? (
                  <Box sx={{ width: 210 }}>
                    <Button
                      size="large"
                      variant="contained"
                      disabled={[isLoading, isShiftLoading, openShiftLoading].some(Boolean)}
                      onClick={() => user && handleLoadShift(user, 'open')}
                      fullWidth
                    >
                      Открыть смену
                    </Button>
                  </Box>
                ) : (
                  customerId && (
                    <Box sx={{ width: 210 }}>
                      <Button
                        size="large"
                        variant="contained"
                        disabled={isLoading}
                        onClick={() => setOpenedCreateModal(true)}
                        fullWidth
                      >
                        Добавить
                      </Button>
                    </Box>
                  )
                )}
              </>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12}>
          {isLoading ? (
            <Loader />
          ) : isError ? (
            <Error text="Произошла ошибка с загрузкой залогов! Пожалуйста, попробуйте позже" />
          ) : isSuccess && Boolean(pledges?.data?.length) ? (
            <PledgesTable
              list={pledges}
              page={page}
              disabled={isFetching}
              refetch={refetch}
              onChangePage={(page) => setQuery({ page })}
            />
          ) : (
            <Empty text="Залоги отсутствуют" />
          )}
        </Grid>
      </Grid>

      {isShiftOpened && (
        <CreatePledge
          disabled={createPledgeResponse.isLoading}
          onSubmit={handleCreatePledge}
          onClose={() => setOpenedCreateModal(false)}
          open={createModalOpened}
        />
      )}
    </>
  );
};

export default PledgesPage;
