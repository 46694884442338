export enum CheckStatuses {
  CARRIED_OUT = 'carried_out',
  CANCELLED = 'cancelled',
}

export interface Check {
  id?: number;
  name: string;
  date_time: string;
  document: string;
  type: string;
  point: string;
  sum: number;
  status: CheckStatuses;
  author: string;
}
