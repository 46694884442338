import { DateParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import moment from 'moment';
import { Grid, MenuItem, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { useGetShiftsQuery } from '@/services';
import { AppBar, DebouncedTextField, Empty, Error, Loader } from '@/components';
import { OrderDirections, ORDER_DIRECTIONS } from '@/utils/order-directions';

import { ShiftsTable } from './components';

const ShiftsPage: React.FC = () => {
  const [{ search, order_direction, page, date_from, date_to }, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    order_direction: withDefault(StringParam, OrderDirections.DESC),
    date_from: withDefault(DateParam, moment().toDate()),
    date_to: withDefault(DateParam, moment().toDate()),
  });

  const {
    data: shifts,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useGetShiftsQuery({
    params: {
      search: search ? search : null,
      order_direction,
      page,
      order_by: 'id',
      date_from: date_from ? moment(date_from).format('YYYY-MM-DD') : null,
      date_to: moment(date_to).format('YYYY-MM-DD'),
    },
  });

  return (
    <>
      <AppBar title="Смены" />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="flex-start" spacing={3}>
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <DebouncedTextField
                  type="search"
                  name="search"
                  label="Поиск"
                  placeholder="Введите значение"
                  value={search}
                  onChange={(event) => setQuery({ search: event.target.value, page: 1 })}
                  variant="filled"
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <TextField
                  select
                  value={order_direction}
                  label="Сортировать по"
                  disabled={isLoading}
                  onChange={(event) => setQuery({ order_direction: event.target.value })}
                  variant="filled"
                  fullWidth
                  size="small"
                >
                  {ORDER_DIRECTIONS.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <DatePicker
                  mask="__.__.____"
                  label="Дата от"
                  value={date_from}
                  minDate={moment(date_to).subtract(6, 'months')}
                  maxDate={moment(date_to)}
                  onChange={(value) => setQuery({ date_from: value ? moment(value).toDate() : undefined })}
                  disabled={isLoading || isFetching}
                  renderInput={(params) => (
                    <TextField {...params} name="date_from" variant="filled" size="small" fullWidth />
                  )}
                />
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <DatePicker
                  mask="__.__.____"
                  label="Дата до"
                  value={date_to}
                  maxDate={moment()}
                  onChange={(value) => setQuery({ date_to: value ? moment(value).toDate() : undefined })}
                  disabled={isLoading || isFetching}
                  renderInput={(params) => (
                    <TextField {...params} name="date_to" variant="filled" size="small" fullWidth />
                  )}
                />
              </Grid>
            </Grid>
          </Stack>
        </Grid>

        <Grid item lg={9} xs={12}>
          {isLoading ? (
            <Loader />
          ) : isError ? (
            <Error text="Произошла ошибка с загрузкой смен! Пожалуйста, попробуйте позже" />
          ) : isSuccess && Boolean(shifts?.data?.length) ? (
            <ShiftsTable list={shifts} page={page} disabled={isFetching} onChangePage={(page) => setQuery({ page })} />
          ) : (
            <Empty text="Смены отсутствуют" />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ShiftsPage;
