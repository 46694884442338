import { useState } from 'react';
import { JsonParam, NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';

import { Point, RealizationStatuses } from '@/types';
import { useCreateRealizationMutation, useGetRealizationPledgesQuery } from '@/services';
import { useAuth, useDebounce } from '@/hooks';
import { CheckEmployeeAdditionalPassword } from '@/pages/Employees/components';
import { SearchPoints } from '@/pages/Points/components';
import { Empty, Error, Loader } from '@/components';
import { messages } from '@/utils/helpers';

import { PledgesTable } from '../components';

export interface CreateRealizationProps extends DialogProps {
  onClose(): void;
}

const CreateRealization: React.FC<CreateRealizationProps> = ({ onClose, ...props }) => {
  const [{ pledges_page, points }, setQuery] = useQueryParams({
    pledges_page: withDefault(NumberParam, 1),
    points: withDefault<Partial<Point>[], Point[]>(JsonParam, []),
  });
  const { user, isCashier, isRevisor, isAdmin } = useAuth();

  const point_ids = useDebounce(points.map(({ id }) => id));
  const emptyFilters = isAdmin || isRevisor ? ![point_ids.length].some(Boolean) : false;

  const [checkAdditionalPasswordModalOpened, setOpenedCheckAdditionalPasswordModal] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const {
    data: pledges,
    isLoading,
    isError,
    isSuccess,
    isFetching,
  } = useGetRealizationPledgesQuery(
    {
      params: { page: pledges_page, point_ids: isCashier ? [user?.point?.id] : point_ids },
    },
    { skip: emptyFilters },
  );

  const [createRealization, createRealizationResponse] = useCreateRealizationMutation();

  return (
    <Dialog fullWidth maxWidth="lg" {...props}>
      <DialogTitle fontWeight="bold">Реализовать залоги</DialogTitle>

      <Formik
        initialValues={{
          realization: pledges?.data.length
            ? { pledges: pledges?.data, status: RealizationStatuses.CARRIED_OUT }
            : { pledges: [] },
          pledges: [],
        }}
        validateOnChange
        validateOnBlur
        enableReinitialize
        validationSchema={yup.object().shape({
          pledges: yup.array().of(yup.object().nullable().required(messages.form.SELECT_FROM_LIST)),
        })}
        onSubmit={async ({ realization, pledges }, { resetForm }) => {
          try {
            await createRealization({ ...realization, pledges, status: RealizationStatuses.CARRIED_OUT }).unwrap();

            onClose();
            resetForm();
          } catch (error) {
            enqueueSnackbar(error.data, { variant: 'error' });
          }
        }}
      >
        {({ values, handleSubmit }) => (
          <>
            <Form>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {(isRevisor || isAdmin) && (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <SearchPoints
                            multiple
                            value={points as Point[]}
                            disableCloseOnSelect
                            onChange={(event, options) =>
                              setQuery({ points: options.map(({ id, name }) => ({ id, name })) })
                            }
                            disabled={isLoading}
                            getOptionLabel={(option) => option.name}
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                name="points"
                                label="Точки"
                                placeholder="Выберите из списка"
                                variant="filled"
                                size="small"
                              />
                            )}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {emptyFilters ? (
                    <Grid item xs={12}>
                      <Empty text="Выберите фильтр по точкам" />
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      {isLoading ? (
                        <Loader />
                      ) : isError ? (
                        <Error
                          text="Произошла ошибка с загрузкой залогов подлежащих реализации! Пожалуйста, попробуйте позже"
                          variant="outlined"
                        />
                      ) : isSuccess && Boolean(pledges?.data?.length) ? (
                        <PledgesTable
                          list={pledges}
                          page={pledges_page}
                          disabled={isFetching || createRealizationResponse.isLoading}
                          onChangePage={(page) => setQuery({ pledges_page: page })}
                        />
                      ) : (
                        <Empty text="Залоги отсутствуют" />
                      )}
                    </Grid>
                  )}
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button
                  type="button"
                  color="secondary"
                  variant="outlined"
                  disabled={createRealizationResponse.isLoading}
                  onClick={onClose}
                >
                  Закрыть
                </Button>

                <Button
                  type="button"
                  variant="contained"
                  disabled={!values.pledges.length || createRealizationResponse.isLoading}
                  onClick={() => setOpenedCheckAdditionalPasswordModal(true)}
                >
                  Провести
                </Button>
              </DialogActions>
            </Form>

            <CheckEmployeeAdditionalPassword
              open={checkAdditionalPasswordModalOpened}
              onCheck={handleSubmit}
              onClose={() => setOpenedCheckAdditionalPasswordModal(false)}
            />
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateRealization;
