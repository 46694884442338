import { DateParam, JsonParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import moment from 'moment';
import { Grid, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { Point, CheckStatuses } from '@/types';
import { useGetChecksQuery } from '@/services';
import { SearchPoints } from '@/pages/Points/components';
import { useAuth, useDebounce } from '@/hooks';
import { OrderDirections, ORDER_DIRECTIONS } from '@/utils/order-directions';
import { AppBar, DebouncedTextField, Empty, Error, Loader } from '@/components';
import { CheckFlat, TimesFlat } from '@/assets/icons';

import { ChecksTable } from './components';

export const CHECK_STATUSES = [
  {
    label: 'Проведён',
    value: CheckStatuses.CARRIED_OUT,
    icon: <CheckFlat sx={{ fontSize: 16 }} color="success" />,
  },
  { label: 'Отменён', value: CheckStatuses.CANCELLED, icon: <TimesFlat sx={{ fontSize: 16 }} color="error" /> },
];

const ChecksPage: React.FC = () => {
  const [{ search, order_direction, page, points, date_from, date_to, status }, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    points: withDefault<Partial<Point>[], Point[]>(JsonParam, []),
    order_direction: withDefault(StringParam, OrderDirections.DESC),
    status: withDefault(StringParam, ''),
    date_from: withDefault(DateParam, moment().toDate()),
    date_to: withDefault(DateParam, moment().toDate()),
  });

  const { user, isCashier, isRevisor, isAdmin } = useAuth();

  const point_ids = useDebounce(points.map(({ id }) => id));
  const emptyFilters = isAdmin || isRevisor ? ![point_ids.length].some(Boolean) : false;

  const {
    data: roles,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useGetChecksQuery(
    {
      params: {
        search: search ? search : null,
        order_direction,
        page,
        point_ids: isCashier ? [user?.point?.id] : point_ids,
        order_by: 'id',
        status,
        date_from: date_from ? moment(date_from).format('YYYY-MM-DD') : null,
        date_to: moment(date_to).format('YYYY-MM-DD'),
      },
    },
    { skip: emptyFilters },
  );

  return (
    <>
      <AppBar title="Чеки" />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {(isRevisor || isAdmin) && (
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <SearchPoints
                  multiple
                  value={points as Point[]}
                  disableCloseOnSelect
                  onChange={(event, options) => setQuery({ points: options.map(({ id, name }) => ({ id, name })) })}
                  disabled={isLoading}
                  getOptionLabel={(option) => option.name}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      name="points"
                      label="Точки"
                      placeholder="Выберите из списка"
                      variant="filled"
                      size="small"
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <DebouncedTextField
                type="search"
                name="search"
                label="Поиск"
                placeholder="Введите значение"
                value={search}
                onChange={(event) => setQuery({ search: event.target.value, page: 1 })}
                variant="filled"
                fullWidth
                size="small"
              />
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <TextField
                select
                value={order_direction}
                label="Сортировать по"
                disabled={isLoading}
                onChange={(event) => setQuery({ order_direction: event.target.value })}
                variant="filled"
                fullWidth
                size="small"
              >
                {ORDER_DIRECTIONS.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <DatePicker
                mask="__.__.____"
                label="Дата от"
                value={date_from}
                minDate={moment(date_to).subtract(6, 'months')}
                maxDate={moment(date_to)}
                onChange={(value) => setQuery({ date_from: value ? moment(value).toDate() : undefined })}
                disabled={isLoading || isFetching}
                renderInput={(params) => (
                  <TextField {...params} name="date_from" variant="filled" size="small" fullWidth />
                )}
              />
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <DatePicker
                mask="__.__.____"
                label="Дата до"
                value={date_to}
                maxDate={moment()}
                onChange={(value) => setQuery({ date_to: value ? moment(value).toDate() : undefined })}
                disabled={isLoading || isFetching}
                renderInput={(params) => (
                  <TextField {...params} name="date_to" variant="filled" size="small" fullWidth />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        {emptyFilters ? (
          <Grid item xs={12}>
            <Empty text="Выберите фильтр по точкам" />
          </Grid>
        ) : (
          <Grid item xs={12}>
            {isLoading ? (
              <Loader />
            ) : isError ? (
              <Error text="Произошла ошибка с загрузкой чеков! Пожалуйста, попробуйте позже" />
            ) : isSuccess && Boolean(roles?.data?.length) ? (
              <ChecksTable list={roles} page={page} disabled={isFetching} onChangePage={(page) => setQuery({ page })} />
            ) : (
              <Empty text="Чеки отсутствуют" />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ChecksPage;
