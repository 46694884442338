import { useState } from 'react';
import moment from 'moment';
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { Buyout, BuyoutStatuses, ListResponse } from '@/types';
import { useRoles } from '@/hooks';
import { Pagination } from '@/components';
import { EditFlat, FileFlat, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { BUYOUT_STATUSES } from '..';
import { DeleteBuyout, GenerateBuyoutDocument, UpdateBuyout } from '../components';

export interface BuyoutListProps {
  list?: ListResponse<Buyout>;
  page: number;
  disabled: boolean;
  onChangePage(page: number): void;
}

const BuyoutsTable: React.FC<BuyoutListProps> = ({ list, page, disabled, onChangePage }) => {
  const { hasRoles } = useRoles();

  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [generateDocumentAnchorEl, setGenerateDocumentAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentBuyout, setCurrentBuyout] = useState<Buyout | null>(null);

  function openUpdateModal(buyout: Buyout) {
    setCurrentBuyout(buyout);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(buyout: Buyout) {
    setCurrentBuyout(buyout);
    setOpenedDeleteModal(true);
  }

  function openGenerateDocumentModal(event: React.MouseEvent<HTMLButtonElement>, buyout: Buyout) {
    setCurrentBuyout(buyout);
    setGenerateDocumentAnchorEl(event.currentTarget);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer
          component={Paper}
          sx={{
            ...(disabled && { opacity: 0.5, pointerEvents: 'none', userSelect: 'none' }),
            maxHeight: `calc(100vh - ${theme.spacing(34.5)})`,
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell>Время</TableCell>
                <TableCell>Наименование</TableCell>
                <TableCell />
                <TableCell>Автор</TableCell>
                <TableCell>Проведён</TableCell>
                <TableCell>Статус</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((buyout) => {
                const { name, employee, carried_by, created_at, status, id } = buyout,
                  buyoutStatus = BUYOUT_STATUSES.find(({ value }) => value === status);

                return (
                  <TableRow key={id}>
                    <TableCell>{moment(created_at).format('DD.MM.YYYY')}</TableCell>
                    <TableCell>{moment(created_at).format('HH:mm')}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title={buyoutStatus?.label}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: 32,
                              height: 32,
                            }}
                          >
                            {buyoutStatus?.icon}
                          </Box>
                        </Tooltip>

                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(buyout)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        {hasRoles(['ADMIN']) && (
                          <Tooltip title="Удалить">
                            <IconButton aria-label="Удалить" onClick={() => openDeleteModal(buyout)}>
                              <TrashFlat sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        )}

                        {![BuyoutStatuses.CANCELLED].includes(status!) && (
                          <Tooltip title="Печать">
                            <IconButton
                              aria-label="Печать"
                              onClick={(event) => openGenerateDocumentModal(event, buyout)}
                            >
                              <FileFlat sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {employee?.last_name ?? ''} {employee?.name ?? ''} {employee?.father_name ?? ''}
                    </TableCell>

                    <TableCell>
                      {carried_by?.last_name ?? ''} {carried_by?.name ?? ''} {carried_by?.father_name ?? ''}
                    </TableCell>
                    <TableCell>{buyoutStatus?.label}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          disabled={disabled}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdateBuyout buyout={currentBuyout!} open={updateModalOpened} onClose={() => setOpenedUpdateModal(false)} />
      <DeleteBuyout buyout={currentBuyout!} open={deleteModalOpened} onClose={() => setOpenedDeleteModal(false)} />
      <GenerateBuyoutDocument
        buyout={currentBuyout!}
        open={generateDocumentAnchorEl !== null}
        anchorEl={generateDocumentAnchorEl}
        onClose={() => setGenerateDocumentAnchorEl(null)}
      />
    </>
  );
};

export default BuyoutsTable;
