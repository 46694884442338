import { useFormikContext } from 'formik';
import moment from 'moment';
import {
  Checkbox,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  darken,
} from '@mui/material';

import { ListResponse, Pledge, Realization } from '@/types';
import { Pagination } from '@/components';
import { PLEDGE_STATUSES } from '@/pages/Pledges';
import theme from '@/theme';

export interface PledgesTableProps {
  list?: ListResponse<Pledge>;
  page: number;
  disabled: boolean;
  onChangePage(page: number): void;
}

const PledgesTable = ({ list, page, disabled, onChangePage }: PledgesTableProps) => {
  const { values, setFieldValue } = useFormikContext<{ realization: Realization; pledges: Pledge[] }>();

  return (
    <Stack spacing={3}>
      <TableContainer
        component={Paper}
        sx={{
          ...(disabled && { opacity: 0.5, pointerEvents: 'none', userSelect: 'none' }),
        }}
      >
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  size="small"
                  indeterminate={values.pledges.length > 0 && values.pledges.length < values.realization.pledges.length}
                  checked={values.pledges.length === values.realization.pledges.length}
                  onChange={(event, checked) => setFieldValue('pledges', checked ? values.realization.pledges : [])}
                  disabled={disabled}
                />
              </TableCell>
              <TableCell>Дата</TableCell>
              <TableCell>Номер</TableCell>
              <TableCell>Клиент</TableCell>
              <TableCell>На руки</TableCell>
              <TableCell>% ставка</TableCell>
              <TableCell>К возврату</TableCell>
              <TableCell>Дата возврата</TableCell>
              <TableCell>Статус</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {values.realization?.pledges?.map((pledge) => {
              const {
                  name,
                  customer,
                  created_at,
                  return_sum_to_hand,
                  rate_for_day,
                  return_sum,
                  return_date,
                  status,
                  id,
                } = pledge,
                pledgeStatus = PLEDGE_STATUSES.find(({ value }) => value === status);

              function handleChange() {
                setFieldValue(
                  'pledges',
                  !values.pledges.find((pledge) => pledge.id === id)
                    ? [...values.pledges, pledge]
                    : values.pledges.filter((pledge) => pledge.id !== id),
                );
              }

              return (
                <TableRow
                  key={id}
                  onClick={handleChange}
                  sx={{
                    cursor: 'pointer',
                    transition: `background-color ${theme.transitions.duration.short}ms`,
                    '&:hover': { bgcolor: darken('#fff', 0.05) },
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={Boolean(values.pledges.find((pledge) => pledge.id === id))}
                      onChange={handleChange}
                      disabled={disabled}
                      size="small"
                    />
                  </TableCell>

                  <TableCell>{created_at ? moment(created_at).format('DD.MM.YYYY') : '—'}</TableCell>
                  <TableCell>{name ?? '—'}</TableCell>
                  <TableCell>
                    {customer ? `${customer.last_name} ${customer.name} ${customer.father_name}` : '—'}
                  </TableCell>
                  <TableCell>{return_sum_to_hand}</TableCell>
                  <TableCell>{rate_for_day}</TableCell>
                  <TableCell>{return_sum}</TableCell>
                  <TableCell>{return_date ? moment(return_date).format('DD.MM.YYYY') : '—'}</TableCell>
                  <TableCell>{pledgeStatus?.label}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        size="medium"
        count={list?.meta.last_page}
        page={page}
        boundaryCount={2}
        disabled={disabled}
        color="primary"
        shape="rounded"
        onChange={(event, value) => onChangePage(value)}
      />
    </Stack>
  );
};

export default PledgesTable;
