import { useState } from 'react';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { Point, ListResponse } from '@/types';
import { truncate } from '@/utils/truncate';
import { Pagination } from '@/components';
import { EditFlat, TrashFlat } from '@/assets/icons';
import theme from '@/theme';

import { DeletePoint, UpdatePoint } from '../components';

export interface PointListProps {
  list?: ListResponse<Point>;
  page: number;
  disabled: boolean;
  onChangePage(page: number): void;
}

const PointsTable: React.FC<PointListProps> = ({ list, page, disabled, onChangePage }) => {
  const [updateModalOpened, setOpenedUpdateModal] = useState(false);
  const [deleteModalOpened, setOpenedDeleteModal] = useState(false);
  const [currentPoint, setCurrentPoint] = useState<Point | null>(null);

  function openUpdateModal(point: Point) {
    setCurrentPoint(point);
    setOpenedUpdateModal(true);
  }

  function openDeleteModal(point: Point) {
    setCurrentPoint(point);
    setOpenedDeleteModal(true);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer
          component={Paper}
          sx={{
            ...(disabled && { opacity: 0.5, pointerEvents: 'none', userSelect: 'none' }),
            maxHeight: `calc(100vh - ${theme.spacing(34.5)})`,
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Наименование</TableCell>
                <TableCell>Код точки транслит.</TableCell>
                <TableCell />
                <TableCell>Адрес</TableCell>
                <TableCell>Префикс док-в</TableCell>
                <TableCell>Руководитель филиала</TableCell>
                <TableCell>Ответственный</TableCell>
                <TableCell>Касса предприятия</TableCell>
                <TableCell>СМС-адрес для оповещения</TableCell>
                <TableCell>Телефоны</TableCell>
                <TableCell>Расписание</TableCell>
                <TableCell>URL видеокамеры для подсчета посетителей</TableCell>
                <TableCell>Дополнительная информация</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((point) => {
                const {
                  additional_information,
                  address_for_sms,
                  cashbox,
                  lead_employee,
                  name_translit,
                  name,
                  phone_numbers,
                  prefix,
                  responsible_employee,
                  schedule,
                  video_monitoring_url,
                  region,
                  area,
                  district,
                  locality,
                  street,
                  house_number,
                  flat_number,
                  id,
                } = point;

                const address = [
                  region?.name,
                  area?.name,
                  district?.name,
                  locality?.name,
                  street?.name,
                  house_number ? 'Дом ' + house_number : null,
                  flat_number ? 'Квартира ' + flat_number : null,
                ]
                  .filter(Boolean)
                  .join(', ');

                return (
                  <TableRow key={id}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{name_translit}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Обновить">
                          <IconButton aria-label="Обновить" onClick={() => openUpdateModal(point)}>
                            <EditFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Удалить">
                          <IconButton aria-label="Удалить" onClick={() => openDeleteModal(point)}>
                            <TrashFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>

                    <TableCell>{address}</TableCell>
                    <TableCell>{prefix}</TableCell>
                    <TableCell>
                      {lead_employee?.last_name ?? ''} {lead_employee?.name ?? ''} {lead_employee?.father_name ?? ''}
                    </TableCell>
                    <TableCell>
                      {responsible_employee?.last_name ?? ''} {responsible_employee?.name ?? ''}{' '}
                      {responsible_employee?.father_name ?? ''}
                    </TableCell>
                    <TableCell>{cashbox?.name ?? '—'}</TableCell>
                    <TableCell>{address_for_sms}</TableCell>
                    <TableCell>{phone_numbers}</TableCell>
                    <TableCell>{schedule}</TableCell>
                    <TableCell>{video_monitoring_url ?? '—'}</TableCell>
                    <TableCell>{additional_information ? truncate(additional_information, 40) : '—'}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          disabled={disabled}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <UpdatePoint point={currentPoint!} open={updateModalOpened} onClose={() => setOpenedUpdateModal(false)} />
      <DeletePoint point={currentPoint!} open={deleteModalOpened} onClose={() => setOpenedDeleteModal(false)} />
    </>
  );
};

export default PointsTable;
