import { useState } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { Check, ListResponse } from '@/types';
import { Pagination } from '@/components';
import theme from '@/theme';
import { FileFlat } from '@/assets/icons';

import { GenerateCheckDocument } from '../components';
import { CHECK_STATUSES } from '..';

export interface CheckListProps {
  list?: ListResponse<Check>;
  page: number;
  disabled: boolean;
  onChangePage(page: number): void;
}

const ChecksTable: React.FC<CheckListProps> = ({ list, page, disabled, onChangePage }) => {
  const [currentCheck, setCurrentCheck] = useState<Check | null>(null);
  const [generateDocumentAnchorEl, setGenerateDocumentAnchorEl] = useState<HTMLButtonElement | null>(null);

  function openGenerateDocumentModal(event: React.MouseEvent<HTMLButtonElement>, check: Check) {
    setCurrentCheck(check);
    setGenerateDocumentAnchorEl(event.currentTarget);
  }

  return (
    <>
      <Stack spacing={3}>
        <TableContainer
          component={Paper}
          sx={{
            ...(disabled && { opacity: 0.5, pointerEvents: 'none', userSelect: 'none' }),
            maxHeight: `calc(100vh - ${theme.spacing(34.5)})`,
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>Дата и время</TableCell>
                <TableCell>Документ-основание</TableCell>
                <TableCell />
                <TableCell>Тип ККМ</TableCell>
                <TableCell>Точка</TableCell>
                <TableCell>Сумма</TableCell>
                <TableCell>Автор</TableCell>
                <TableCell>Статус</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.data.map((check) => {
                const { date_time, name, document, type, point, sum, author, status, id } = check,
                  checkStatus = CHECK_STATUSES.find(({ value }) => value === status);

                return (
                  <TableRow key={id}>
                    <TableCell>{name ?? '—'}</TableCell>
                    <TableCell>{date_time}</TableCell>
                    <TableCell>{document}</TableCell>

                    <TableCell padding="checkbox">
                      <Stack direction="row" spacing={1}>
                        <Tooltip title={checkStatus?.label}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: 32,
                              height: 32,
                            }}
                          >
                            {checkStatus?.icon}
                          </Box>
                        </Tooltip>

                        <Tooltip title="Печать">
                          <IconButton aria-label="Печать" onClick={(event) => openGenerateDocumentModal(event, check)}>
                            <FileFlat sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>

                    <TableCell>{type ?? '—'}</TableCell>
                    <TableCell>{point ?? '—'}</TableCell>
                    <TableCell>{sum ?? '—'}</TableCell>
                    <TableCell>{author ?? '—'}</TableCell>
                    <TableCell>{checkStatus?.label}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          size="medium"
          count={list?.meta.last_page}
          page={page}
          boundaryCount={2}
          disabled={disabled}
          color="primary"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
      </Stack>

      <GenerateCheckDocument
        check={currentCheck!}
        open={generateDocumentAnchorEl !== null}
        anchorEl={generateDocumentAnchorEl}
        onClose={() => setGenerateDocumentAnchorEl(null)}
      />
    </>
  );
};

export default ChecksTable;
